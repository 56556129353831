import React, { useState } from 'react';
import Layout from "../components/layout"
import { Home } from '../components/team/home';
import { Time } from '../components/team/time';
import { Status } from '../components/team/status';
import { Tickets } from '../components/team/tickets';
import { Projects } from '../components/team/projects';
import { Navigation } from '../components/team/navigation';
import { Announcements } from '../components/team/announcements';
import { login, isAuthenticated, getProfile } from "../utils/auth"
import { Resources } from '../components/team/resources';



const Team = () => {
    const [navItem, setNavItem] = useState('');

    if (!isAuthenticated()) {
        login()
        return <p>Redirecting to login...</p>
      }

      const user = getProfile()

    return(
        <Layout>
            <Navigation selectedNavItem={(e) => setNavItem(e)} />
            { navItem === '' &&
                <Home />
            }

            { navItem === 'TIME' &&
                <Time />
            }

            { navItem === 'STATUS' &&
                <Status />
            }

            { navItem === 'TICKETS' &&
                <Tickets />
            }

            { navItem === 'ANNOUNCEMENTS' &&
                <Announcements />
            }

            { navItem === 'PROJECTS' &&
                <Projects />
            }

            { navItem === 'RESOURCES' &&
                <Resources />
            }
        </Layout>
        )
}

export default Team;